h1 {
    font-size: 2.25rem;

    @media (min-width: 576px) {
        font-size: 3.3125rem;
        line-height: 1.15em;
    }
}

h2 {
    font-size: 1.75rem;

    @media (min-width: 576px) {
        font-size: 2.25rem;
        line-height: 1.5em;
    }
}

.mt-1 {
    margin-top: 1em;
}

.text-center {
    text-align: center;
}

.p10 {
    padding: 10px !important;
}

.m8 {
    margin: 8px !important;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
    color: #e91e63 !important;
}

.custom-carousel-margin {
    margin-bottom: 70px;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.slick-slider .slick-slide>div:first-child {
    position: relative !important;
    margin-right: 15px !important;
    margin-left: 15px !important;
}

.slick-prev:before,
.slick-next:before {
    color: #00acc1 !important;
    margin-top: 20px !important;
}

.video-container {
    background-color: transparent;
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
}