.brand {
    h1 {
        @media (max-width: 576px) {
            font-size: 2.5rem !important;
        }
    }

    .pro-badge {
        display: block;
        width: fit-content;
        margin: 0 auto;
        color: #444;
        padding: 10px 18px;
        font-size: 22px;
        background: #fff;
        box-shadow: 0 5px 5px -2px rgba(31, 31, 31, 0.4);
        font-weight: 700;
        line-height: 22px;
        border-radius: 3px;
        text-transform: uppercase;
        @media (min-width: 576px) {
            position: relative;
            top: -30px;
            right: -10px;
            display: inherit;
        }
    }

    .banner {
        color: #444;
        background: #fff;
        font-size: 1.5625rem;
        line-height: 1.4em;
        padding: 10px 18px;
        border-radius: 3px;
        box-shadow: 0 5px 5px -2px rgba(31, 31, 31, 0.4);
    }
}
