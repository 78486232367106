.image-text-wrapper {
    img { 
        float: left;  
        margin-right: 15px;
        margin-top: 5px;
        margin-bottom: 20px;

        @media (min-width: 576px) {
            max-width: 200px;
            margin-bottom: 10px;
        }
    } 
    p { 
        text-align: justify; 
        font-size: 25px; 
    } 
}
